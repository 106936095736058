<template>
  <ClientOnly>
    <Teleport to="#app">
      <div v-if="props.photos" class="lb" ref="galleryEl">
        <div class="scrl" ref="scrollEl" tabindex="0">


          <div 
            v-for="(photo, index) in props.photos"
            :key="photo.id"
            :ref="el => { if (el) setPhotoRef(el, photo.id) }"
            :class="{'lc': true, 'zoom': zoomed}"
            :data-id="photo.id"
          >

            <iframe loading="lazy"
              v-if="photo.type === 'iframe'"
              class="iframe"
              :src="photo.iframe"
            >
            </iframe>


            <img loading="lazy" 
              v-else
              ref="imgEl"
              class="image"
              :style="imageTransformStyle"
              :src="zoomed ? photo.base : photo.zoom"
              v-dbltap="toggleZoom"
              @mousedown="onPointerDown($event)"
              @touchstart="onPointerDown($event)"
            />

            <div 
              v-if="photo.caption"
              class="caption text-ellipsis-2"
            >
              {{ photo.caption }}
            </div>

            <div class="gloading"></div>

            <div class="bglb" @click="closeLightbox"></div>          

          </div> 


        </div>

        <!-- <NuxtLink v-if="downloadBtnLink" target="_black" :to="downloadBtnLink" class="external-btn">
          <i class="icon-download"></i>
        </NuxtLink> -->

        <div class="close-btn" @click="closeLightbox">
          <i class="icon-close-thin"></i>
        </div>

      </div>
    </Teleport>
  </ClientOnly>
</template>

<script setup lang="ts">
import { ref, computed, nextTick, onMounted, onUnmounted, defineProps, defineEmits } from 'vue';
import type { PhotoGallery } from '@/types/photo';

/** Исходные пропсы */
interface Props {
  photoId: number | null;
  photos: Array<PhotoGallery>;
}
const props = defineProps<Props>();
const emits = defineEmits(['close']);

/** Существующие reactive-переменные */
const zoomed = ref(false);
const galleryEl = ref<HTMLElement | null>(null);
const current = ref(props.photoId);
const photoRefs = ref<HTMLElement[]>([]);
const scrollEl = ref<HTMLElement | null>(null);
const scrollToBehavior = ref<'instant' | 'smooth'>('instant');

/** 
 * Добавляем переменные для работы с ручным зумом и перетаскиванием:
 * - scale: текущее увеличение
 * - offsetX, offsetY: текущее смещение
 * - startX, startY: координаты начала жеста
 * - isDragging: флаг перетаскивания
 */
const scale = ref<number>(1);
const offsetX = ref<number>(0);
const offsetY = ref<number>(0);
const startX = ref<number>(0);
const startY = ref<number>(0);
const isDragging = ref<boolean>(false);

/** 
 * Для распознавания пинча на мобильных 
 * будем хранить расстояние между пальцами при pinchStart (initialPinchDist),
 * чтобы вычислять изменение расстояния (и, соответственно, изменения scale).
 */
const initialPinchDist = ref<number>(0);

const setPhotoRef = (el: HTMLElement, id: number) => {
  photoRefs.value[id] = el;
};

const downloadBtnLink = computed(() => {
  if(!props.photos || !props.photos.length) return null;
  const photo = props.photos.find(p => p.id === current.value);
  if(!photo) return null;
  return photo.zoom;
});

const calcStyle = computed(() => {
  return (photo: PhotoGallery) => {
    if(!photo.width || !photo.height){
      return {};
    }
    return {
      // можно настроить aspect-ratio при необходимости
      // 'aspect-ratio': `${photo.width}/${photo.height}`,
    };
  };
});

/** 
 * Вычисляем стили для трансформации 
 * (перемещение + зум картинки).
 */
const imageTransformStyle = computed(() => {
  return {
    transform: `translate(${offsetX.value}px, ${offsetY.value}px) scale(${scale.value})`,
    cursor: zoomed.value ? 'grab' : 'pointer',
    transition: isDragging.value ? 'none' : 'transform 0.2s ease-out'
  };
});

/**
 * Обработчик для закрытия лайтбокса
 */
const closeLightbox = () => {
  emits('close');
};

/**
 * Сбрасывает всё к дефолтному состоянию зума
 */
const resetZoom = () => {
  scale.value = 1;
  offsetX.value = 0;
  offsetY.value = 0;
};

/**
 * Переключение состояния зума по двойному клику
 * (на мобилке - дабл-тап)
 */
const toggleZoom = (e: MouseEvent | TouchEvent) => {
console.log('toggleZoom');
  if (!zoomed.value) {
    // Активируем зум
    zoomed.value = true;
    // По умолчанию установим scale чуть больше 1 (или оставим равным 1),
    // если хотим “автоматический” небольшой зум
    scale.value = 2;
  } else {
    // Выключаем зум, сбрасываем смещения
    zoomed.value = false;
    resetZoom();
  }
};

/** 
 * Переход к следующей/предыдущей фотографии из Keyboard events — уже есть
 * в вашем коде, ничего менять тут не обязательно, но можно подправить,
 * чтобы при смене фото сбрасывался зум:
 */
const shakeGallery = () => {
  let currentEl = photoRefs.value[current.value]?.querySelector('img');
  if(currentEl){
    currentEl.classList.add('shake');
    setTimeout(() => {
      currentEl.classList.remove('shake');
    }, 500);
  }
};

const scrollToPhoto = () => {

  if(current.value){
    if(!photoRefs.value[current.value]?.getBoundingClientRect) return;
    const elementRect = photoRefs.value[current.value].getBoundingClientRect();
    const elementTop = elementRect.top;
    if(!scrollEl.value) return;
    const targetEl = photoRefs.value[current.value];
    if (!targetEl) return;

    // console.log('scrollToPhoto', photoRefs.value[current.value]);
    // console.log('elementTop', elementTop);
    scrollEl.value.scrollTo({
      // top: elementTop - 50, 
      top: targetEl.offsetTop - 50,
      behavior: scrollToBehavior.value
    });
  }
};

const pressKey = (event: KeyboardEvent) => {
  let triggered = false;
  if (event.key === "Escape") {
    closeLightbox();
    triggered = true;
  }
  if (event.key === "ArrowRight" || event.key === "ArrowDown") {
    if (props.photos?.length) {
      const currentIndex = props.photos.findIndex((p) => p.id === current.value);
      const nextIndex = currentIndex + 1;
      if (nextIndex < props.photos.length) {
        console.log('nextIndex', props.photos[nextIndex].source.video_id);
        console.log('nextIndex', props.photos[nextIndex].source);
        current.value = props.photos[nextIndex].id;
        scrollToPhoto();
        // Сбрасываем зум при переключении
        resetZoom();
        zoomed.value = false;
      } else {
        shakeGallery();
      }
    }
    triggered = true;
  }
  if (event.key === "ArrowLeft" || event.key === "ArrowUp") {
    if (props.photos?.length) {
      const currentIndex = props.photos.findIndex((p) => p.id === current.value);
      const prevIndex = currentIndex - 1;
      if (prevIndex >= 0) {
        current.value = props.photos[prevIndex].id;
        scrollToPhoto();
        // Сбрасываем зум при переключении
        resetZoom();
        zoomed.value = false;
      }else{
        shakeGallery();
      }
    }
    triggered = true;
  }
  if(triggered){
    event.preventDefault();
    event.stopPropagation();
  }
};



onMounted(() => {
  nextTick(() => {
    if(import.meta.client){
      scrollEl.value?.focus();
      scrollEl.value.addEventListener('keydown', pressKey);
    }
  });

  nextTick(() => {
    scrollToPhoto();
    scrollToBehavior.value = 'smooth';
  });
});

onUnmounted(() => {
  if(scrollEl.value){
    scrollEl.value.removeEventListener('keydown', pressKey);
  }
});

/* ========== 
   ОБРАБОТЧИКИ ЗУМА И ПЕРЕТАСКИВАНИЯ 
   ========== */
   
function getTouchDistance(touches: TouchList) {
  // Вычисляем расстояние между двумя пальцами
  if (touches.length < 2) return 0;
  const touch1 = touches[0];
  const touch2 = touches[1];
  const dx = touch2.clientX - touch1.clientX;
  const dy = touch2.clientY - touch1.clientY;
  return Math.sqrt(dx*dx + dy*dy);
}

/**
 * Универсальный обработчик для начала жеста (mousedown/touchstart)
 */
const onPointerDown = (e: MouseEvent | TouchEvent) => {

  // console.log('zoomed', zoomed.value);
  // Если не в состоянии зума, то при одиночном тапе/клике 
  // не даём перетаскивать изображение
  console.log('onPointerDown');
  if (!zoomed.value) return;
  console.log('onPointerDown start');


  e.preventDefault();

  isDragging.value = true;

  // Если это TouchEvent и пальцев > 1, значит pinch
  if (e instanceof TouchEvent && e.touches.length > 1) {
    initialPinchDist.value = getTouchDistance(e.touches);        
  } 
  else if (e instanceof TouchEvent && e.touches.length === 1) {
    const touch = e.touches[0];
    startX.value = touch.clientX - window.innerWidth / 2 - offsetX.value;
    startY.value = touch.clientY - window.innerHeight / 2 - offsetY.value;
    // console.log('start:', startX.value, startY.value);
  } 
  else if (e instanceof MouseEvent) {
    // Для десктопа запоминаем начальные координаты
    startX.value = (e as MouseEvent).clientX - offsetX.value;
    startY.value = (e as MouseEvent).clientY - offsetY.value;
  }
  
  window.addEventListener('mousemove', onPointerMove);
  window.addEventListener('touchmove', onPointerMove, { passive: false });
  window.addEventListener('mouseup', onPointerUp);
  window.addEventListener('touchend', onPointerUp);
};

/**
 * Обработчик при движении (mousemove/touchmove)
 */
const onPointerMove = (e: MouseEvent | TouchEvent) => {
  if (!isDragging.value) return;
  

  // Pinch
  if (e instanceof TouchEvent && e.touches.length > 1) {
    const newDist = getTouchDistance(e.touches);
    if (initialPinchDist.value === 0) return;
    const pinchScale = newDist / initialPinchDist.value;
    scale.value = Math.max(1, Math.min(4, scale.value * pinchScale));
    // Чтобы последующие pinch-движения учитывали новое текущее расстояние
    initialPinchDist.value = newDist;
    return;
  }

  // Drag для одной точки (один палец или мышь)
  if (e instanceof TouchEvent) {
    // Для одного пальца
    if (e.touches.length === 1) {
      const touch = e.touches[0];
      // offsetX.value = touch.clientX - startX.value;
      // offsetY.value = touch.clientY - startY.value;
      offsetX.value = touch.clientX - window.innerWidth / 2 - startX.value;
      offsetY.value = touch.clientY - window.innerHeight / 2 - startY.value;
      // console.log('move start: ', startX.value, startY.value);
      // console.log('move offset: ', offsetX.value, offsetY.value);
    }
  } else {
    // Для мыши
    offsetX.value = (e as MouseEvent).clientX - startX.value;
    offsetY.value = (e as MouseEvent).clientY - startY.value;
  }
};

/**
 * Завершаем жест
 */
const onPointerUp = (e: MouseEvent | TouchEvent) => {
  isDragging.value = false;
  initialPinchDist.value = 0;
  
  window.removeEventListener('mousemove', onPointerMove);
  window.removeEventListener('touchmove', onPointerMove);
  window.removeEventListener('mouseup', onPointerUp);
  window.removeEventListener('touchend', onPointerUp);
};

/**
 * Обработчик колёсика (wheel) для десктопа
 * Позволяет менять масштаб колёсиком мыши
 */
const onWheel = (e: WheelEvent) => {
  
  if (zoomed.value) {
    resetZoom();
    zoomed.value = false;
  }
  return;
  e.preventDefault();

  const delta = e.deltaY < 0 ? 0.1 : -0.1;
  scale.value += delta;
  scale.value = Math.max(1, Math.min(4, scale.value));

  if(scale.value === 1){
    zoomed.value = false;
    resetZoom();
  }
};
</script>

<style scoped>
.lb {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99999;
}
.lb .scrl {
  overflow-y: auto;
  overflow-x: hidden; 
  max-height: calc(100vh - 0px);
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  align-items: center;
  z-index: 1;
  width: 100%;
  overscroll-behavior: contain;
  scroll-snap-type: y mandatory;
}
.bglb {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgb(0 0 0 / 92%);
}
.lc {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100dvh; 
  width: 100dvw;
  padding: 1rem;
  min-height: 100vh;
  width: 100vw;
  scroll-snap-stop: always;
  scroll-snap-align: center;
}
.lc img {
  user-select: none;
  -webkit-user-select: none;
  max-width: 100%;
  max-height: 100%;
  border-radius: 10px;
  z-index: 2;
  cursor: zoom-in;
}
.lc.zoom img {
  cursor: zoom-in;
}
.lc iframe {
  pointer-events: all;

  height: max-content;
    width: max-content;
    z-index: 2;

    width: 100%;
    height: 100%;
    border: 0;
}
.external-btn {
  position: absolute;
  top: 10px;
  right: 70px;
  color: white;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  padding: 15px;
  font-size: 1.1rem;
  z-index: 1;
}

.close-btn {
  position: absolute;
  top: 0px;
  right: 10px;
  color: white;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  padding: 15px;
  font-size: 1.6rem;
  z-index: 1;
}
.caption {
  position: absolute;
  bottom: 1rem;
  left: 0;
  right: 0;
  background-color: #00000052;
  padding: 1rem;
  color: white;
  font-size: 0.9rem;
  max-width: 500px;
  margin: 0 auto;
  border-radius: 10px;
  z-index: 111;
  white-space: pre-wrap;
}
.lc.zoom {
  height: 100vh;
  width: 100vw;
  max-width: none;
  max-height: none;
  overflow: hidden;
}
.lc.zoom img {
  cursor: grab;
}
/* Анимация shake */
.shake {
  animation: shake 0.3s;
}
@keyframes shake {
  0% { transform: translateX(0); }
  20% { transform: translateX(-10px); }
  40% { transform: translateX(10px); }
  60% { transform: translateX(-10px); }
  80% { transform: translateX(10px); }
  100% { transform: translateX(0); }
}
</style>